.App {
	position: relative;
	text-align: center;
	width: 100vw;
	height: 110vh;
	/* background-color: #303030; */
	background: linear-gradient(to bottom, #303030 90%, #828282);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

#block1 {
	position: absolute;
	top: 10vh;
	left: 5vw;
	bottom: unset;
	right: unset;
}

#block2 {
	position: absolute;
	top: 10vh;
	right: 5vw;
	bottom: unset;
	left: unset;
}

#block3 {
	position: absolute;
	bottom: 20vh;
	left: 5vw;
	top: unset;
	right: unset;
}

#block4 {
	position: absolute;
	bottom: 20vh;
	right: 5vw;
	left: unset;
	top: unset;
}

@media screen and (max-width: 600px) {
	.App {
		flex-direction: row;
	}
	#block1 {
		position: absolute;
		top: 5vh;
		left: 5vw;
	}
	#block2 {
		position: absolute;
		top: 20vh;
		left: 5vw;
	}
	#block3 {
		position: absolute;
		top: 65vh;
		left: 5vw;
	}
	#block4 {
		position: absolute;
		top: 80vh;
		left: 5vw;
	}
}