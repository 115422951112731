.menu {
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	z-index: 99;
}

.menu input {
	display: none;
}

.menu a {
	color: unset;
}

.btn {
	width: min(80px, 8vw);
	height: min(80px, 8vw);
	border-radius: min(80px, 8vw);
	position: absolute;
	overflow: hidden;
	cursor: pointer;
}

.btn {
	background: #eceff1;
	font-size: 32px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	box-shadow: 11px 11px 47px #181818, -11px -11px 47px #4d4d4d;

	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn:not(:first-child):hover {
	transition: transform 0.3s ease 0s;
	transform: scale(1.3);
}

.btn:not(:first-child) {
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	z-index: -2;

	-webkit-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
	transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.btn:nth-child(2) {
	top: 0px;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}
.btn:nth-child(3) {
	top: 0px;
	left: 0px;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.btn:nth-child(4) {
	left: 0px;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.btn:nth-child(5) {
	top: 0px;
	left: 0px;
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.btn:nth-child(6) {
	top: 0px;
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
.btn:nth-child(7) {
	top: 0px;
	left: 0px;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}
.btn:nth-child(8) {
	left: 0px;
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
}
.btn:nth-child(9) {
	top: 0px;
	left: 0px;
	-webkit-transition-delay: 0.7s;
	transition-delay: 0.7s;
}

input#toggle:checked ~ #show-menu .btn:nth-child(2) {
	top: calc(-1 * min(250px, 25vw));
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(3) {
	top: calc(-1 * min(160px, 16.66vw));
	left: min(160px, 16.66vw);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(4) {
	left: min(250px, 25vw);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(5) {
	top: min(160px, 16.66vw);
	left: min(160px, 16.66vw);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(6) {
	top: min(250px, 25vw);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(7) {
	top: min(160px, 16.66vw);
	left: calc(-1 * min(160px, 16.66vw));
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(8) {
	left: calc(-1 * min(250px, 25vw));
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(9) {
	top: calc(-1 * min(160px, 16.66vw));
	left: calc(-1 * min(160px, 16.66vw));
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.menuBtn,
.closeBtn {
	position: absolute;
	transition: all 0.3s ease;
}

.closeBtn {
	transform: translateY(50px);
	opacity: 0;
}

input#toggle:checked ~ #show-menu .btn .menuBtn {
	transform: translateY(-50px);
	opacity: 0;
}

input#toggle:checked ~ #show-menu .btn .closeBtn {
	transform: translateY(0px);
	opacity: 1;
}

.HomePic-container {
	width: fit-content;
	height: fit-content;
	border-radius: unset;
	overflow: visible;
	cursor: pointer;
	background-color: transparent;
	box-shadow: unset;
	left: 50%;
	transform: translate(-25%, -33%);
}

.HomePic {
	width: min(250px, 25vw);
	max-width: 200px;
	height: unset;
}

.HoverText,
.ClickText {
	position: absolute;
	overflow: visible;
	width: -webkit-fill-available;
	top: -30px;
}
.ClickText {
	display: none;
}

/* RESPONSIVENESS */

@media only screen and (max-width: 600px) {
	.btn {
		font-size: 16px;
	}
	.ClickText {
		display: block;
	}
	.HoverText {
		display: none;
	}
}
