html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.contact-plane {
	position: absolute;
	z-index: 100;
	width: auto;
	height: auto;
}

.plane {
	position: relative;
	width: 100px;
	height: 100px;
}
@media screen and (max-width:768px){
	.plane {
		display: none;
	}
}

.plane-contact {
	position: relative;
	width: 100px;
	height: 100px;
	transform: scaleX(-1);
}
.plane-trail {
	position: relative;
	width: 100px;
	height: 100px;
	transform: scaleX(-1);
}

/* Scrollbar Styling */
::-webkit-scrollbar {
	width: 10px;
	background-color: #303030;
}

::-webkit-scrollbar-track {
	background-color: #ebebeb;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #6d6d6d;
}

::-webkit-scrollbar-thumb:hover {
	transform: scale(1.2);
}
