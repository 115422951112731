@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.projects {
	position: relative;
	width: 100vw;
	height: fit-content;
	min-height: 100vh;
	background: linear-gradient(to bottom, #ffffff, #f1f1f1);
	--swiper-theme-color: rgb(235, 235, 235);
	--swiper-pagination-color: black;
}

.swiper-container {
	position: relative;
	width: 100%;
	height: 100%;
	top: 20vh;
	padding-top: 50px;
	padding-bottom: 50px;
}

.swiper-slide {
	background-position: center;
	background-size: cover;
	width: 300px;
	height: 400px;
	box-shadow: 2px 2px solid black;
}

.project-detail {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 40%;
	background-color: #00000041;
	transition: all 0.5s ease-in-out;
	backdrop-filter: blur(1px);
}

.project-detail:hover {
	background-color: #000000b9;
	color: white;
}

.project-title {
	position: relative;
	width: auto;
	text-align: center;
	height: 20%;
	font: 600 1em Montserrat;
	margin: 5%;
	margin-bottom: 1%;
}

.project-context {
	position: relative;
	width: auto;
	height: auto;
	max-height: 50%;
	font: 400 0.9em Montserrat;
	margin: 5%;
	margin-top: 1%;
}

.project-github {
	position: absolute;
	right: 10px;
	bottom: 10px;
	width: 30px;
	height: 30px;
	background-size: cover;
	background-image: url(../imgs/TechIcons/github-icon-white.png);
}

.project-link {
	position: absolute;
	right: 50px;
	bottom: 10px;
	width: 30px;
	height: 30px;
	background-size: cover;
	background-image: url(../imgs/TechIcons/Link-icon.png);
}

.text-white {
	color: white;
}

.bulb {
	position: absolute;
	left: 47%;
	top: 8%;
}

.bulboff {
	position: absolute;
	width: 100px;
	opacity: 1;
}

.bulbon {
	position: absolute;
	width: 100px;
	opacity: 0;
}

.bulb:hover .bulbon {
	opacity: 1;
}

.swiper-button-next {
	right: 60px;
}

.swiper-button-prev {
	left: 60px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	text-shadow: 2px 3px 3px #747474;
}

@media screen and (max-width: 768px) {
	.bulb {
		top: 3%;
		left: 65%;
	}
	.override-title {
		top: 6%;
		left: 10%;
	}

	.project-detail {
		background-color: #000000b9;
		color: white;
	}
}
