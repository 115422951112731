@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.skills {
	position: relative;
	width: 100vw;
	height: auto;
	min-height: 100vh;
	overflow: hidden;
	background: linear-gradient(to bottom, #f1f1f1, #ffffff);
	display: flex;
	flex-direction: row;
}

.skill-col {
	position: relative;
	width: 33%;
	height: 90vh;
	min-height: fit-content;
	margin-top: 10vh;
}

.skills .skill-col:nth-child(3)::before{
	position: absolute;
	border-left: 1px solid black;
    height: 300px;
    top: 20vh;
	content: "";
}

.skills .skill-col:nth-child(3)::after{
	position: absolute;
	border-right: 1px solid black;
    height: 300px;
	right: 0;
    top: 20vh;
	content: "";
}

.skill-title {
	position: absolute;
	width: 100%;
	height: auto;
	top: 14px;
	text-align: center;

	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 64px;
	line-height: 78px;
	text-align: center;

	color: #000000;
}

.skill-heading {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: #000000;
	margin-bottom: 5vh;
	display: block;
}

.skill-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4vh;
	margin-bottom: 5vh;
}

.lang {
	position: relative;
	top: 20vh;
	width: auto;
	height: fit-content;
}

.front {
	position: relative;
	top: 8vh;
	width: auto;
	height: fit-content;
}

.back {
	position: relative;
	top: 10vh;
	width: auto;
	height: fit-content;
}

.dbaot {
	position: relative;
	top: 10vh;
	width: auto;
	height: fit-content;
}

.ss {
	position: relative;
	top: 20vh;
	width: auto;
	height: fit-content;
}

.ss-text {
	position: absolute;
    display: grid;
    top: 19vh;
    width: 96%;
    grid-template-columns: 44% 44%;
    grid-template-rows: 19vh auto;
    text-align: center;
    font-family: 'Montserrat';
    left: 9%;
}

@media screen and (max-width: 1000px) {
	.skills {
		flex-direction: column;
		min-height: fit-content;
	}
	.skill-col {
		width: 100%;
		height: fit-content;
		min-height: unset;
	}
	.skills .skill-col:last-child {
		min-height: 60vh;
	}
	.lang {
		top: 8vh;
	}
	.front {
		top: 2vh;
	}
	.ss {
		top: 8vh;
	}
}
